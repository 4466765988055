<template>
  <div class="flex-container">
    <template v-for="trip in trips">
      <router-link
        :key="trip.id"
        :to="`/trips/${trip.id}`"
        class="trip flex-item"
        :style="`background-image: url('${trip.trip_img}')`"
      >
        <div class="trip-details">
          <div class="trip-header" v-text="trip.displayName"></div>
          <div class="trip-subheader" v-text="trip.displayName2"></div>
          <div class="trip-subheader" v-text="trip.date"></div>
          <div class="trip-content" v-text="trip.shortDescription"></div>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import { firebase } from "../plugins";

export default {
  name: "Trips",
  data: () => ({
    trips: []
  }),
  firestore: {
    trips: firebase.tripsRef.orderBy("order")
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  column-gap: 16px;
  row-gap: 16px;

  margin: 0px var(--main-right-margin) 0px var(--main-left-margin);
}

.trip {
  cursor: pointer;

  border-radius: 10px;
  background-size: 100% 100%;
  background-size: cover;
  transition: 0.5s;

  flex: 1 300px;
  height: 400px;
}


.trip-details {
  margin-top: 45px;
  padding: 15px 30px;
  background-color: var(--footer-color);
}

.trip-header {
  color: white;
  font-size: 45px;
  word-break: break-all;
  font-weight: bold;
}
.trip-subheader {
  color: white;
}

@media (max-width: 800px) {
  .trip {
    height: 240px;
  }
}
</style>
